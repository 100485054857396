import React, { useState, useEffect } from "react";
import styles from "./inspection-review-footer.module.css";
import Button from "../shared/button/component";
import {
    getCarDetailRejectedfieldObject,
    getCarDetailPostQcEditablefieldObject, getOtherCommentsJson,
    getACRejectedfieldObject,
    getSteeringSuspensionRejectedfieldObject,
    getEngineTransmissoinRejectedfieldObject

} from "../../utils/post/postHelper";
import {
    getExteriorTyresRejectedfieldObject,
    getInteriorExteriorPostQcEditablefieldObject
} from "../../utils/post/postHelperExteriorTyres";
import { getFromLocalStorage, showToastMessages, navigateToHomeWithDelay, isAssignedToMe } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import Checkbox from "../shared/checkbox";
import { editableFielKeys, nonEditableFieldKeysForCarDetailSection } from "../../utils/app-constants";
import Popup from "../../components/popup";
import InspectionConfirmationPopup from "../../components/inspection-confirmation-popup/";
import DummyInspectionConfirmationPopup from '../../components/dummy-inspection-confirmation-popup';
import RejectionPopup from "../../components/rejection-popup/";

const InspectionReviewFooter = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect,
    submitReviewConnect,
    history,
    rejectionDataList,
    setIsReviewSubmitting,
    setRefresh,
}) => {
    const { carId, documentsMapper, insuranceMapper, mismatchMapper, otherDetailsMapper,
        externalDataMapper, exteriorTyresMapper, interiorImagesMapper,
        interiorDataMapper, insuranceImageMapper, odometerMapper, airConditioningDataMapper,
        makeModelVariantMapper, isCritical, rcImagesMapper, mapperCounts, commentsMapper,
        lhsFrontTyremapper, rhsFrontTyremapper, lhsRearTyremapper, rhsRearTyremapper, spareTypremapper,
        duplicateKeyMapper, rejectionRemark, exteriorComments, issueType, qcAssign, additionalImagesMapper,
        interiorOdometerMapper, selectedVersion, odometerComments, interiorElecctricalsMapper, version } = inspectionDetails;

    const { Documents, EXTERIOR, INTERIOR, SUMMARY } = !!mapperCounts ? mapperCounts : {};
    const [showCritical, setShowCritical] = useState(false);
    const [isDummyInspection, setIsDummyInspection] = useState(false);
    const [dummyPopupOpen, setDummyPopupOpen] = useState(false);
    const currentUser = qcAssign ? qcAssign.to : "";
    const reviewStartTime = new Date();
    const onTextAreaChange = ({ target }) => {
        const { value } = target;

        resetInspectionDetailByKeyConnect("rejectionRemark", value);
    };
    
    const isValidated = () => {
        let flag = true;
        let inValidDate = false;
        //documents Mapper
        let nonEditableFields = {
            // rcAvailability: "rcAvailability",
            // isUnderHypothecation: "isUnderHypothecation",
            bankNocStatus: "bankNocStatus",
            bankNocExpiryDate: "bankNocExpiryDate",
        };

        let fields = [];
        let remarkReqdForFields = [];
        let odoCheck = documentsMapper.filter(item => item.checked === true && item.key === "odometerReading");
        if (!!odoCheck && odoCheck.length > 0) {
            if (parseInt(odoCheck[0].newValue) <= 0) {
                flag = false;
                fields.push(odoCheck[0].text);
            }
        }
        //special case for fitness upto
        let fitnessUpto = documentsMapper.filter(item => item.checked === true && item.key === "fitnessUpto");
        if (!!fitnessUpto && fitnessUpto.length > 0) {
            if (!!fitnessUpto[0].newValue && fitnessUpto[0].newValue === "Invalid Date") {
                flag = false;
                inValidDate = true;
                fields.push(fitnessUpto[0].text);
            }
        }

        let rtoNocAvailableImage = documentsMapper.filter(item => item.checked === true && item.key === "rtoNocAvailable");
        if (!!rtoNocAvailableImage && rtoNocAvailableImage.length > 0) {
            if(!!rtoNocAvailableImage[0]?.newValue && !rtoNocAvailableImage[0]?.additionalInfo?.length){
                flag = false;
                fields.push("RTO NOC Available Image");
            }
        }

        documentsMapper.map(item => {
            if (item.checked) {
                if (!!item.newId && Array.isArray(item.newId)) {
                    if (!item.newValue && item.newId.length === 0 && !nonEditableFields[item.key]) {
                        flag = false;
                        fields.push(item.text);
                    }
                }
                else {
                    if (!item.newValue && !item.newId && !nonEditableFields[item.key]) {
                        flag = false;
                        fields.push(item.text);
                    }
                }
            }
            if (item.checked && item.isValid === false) {
                flag = false;
                fields.push(item.text);
            }

        });

        insuranceMapper.map(item => {
            if (item.checked && !item.newValue && !item.newId && !nonEditableFields[item.key]) {
                flag = false;
                fields.push(item.text);
            }
        });
        interiorOdometerMapper.map(item => {
            if (item.checked && !item.newValue && !item.newId && !nonEditableFields[item.key]) {
                flag = false;
                fields.push(item.text);
            }
            else if (item.newValue === "0") {
                flag = false;
                fields.push(item.text);
            }
        });

        interiorElecctricalsMapper.map(item => {
            if(item.checked && item.key === 'fuelLevel' && !item.newValue && !nonEditableFields[item.key]) {
                flag = false;
                fields.push(item.text);
            }
        });

        let remarkCommentCheckInMappers = [
            "documentsMapper",
            "insuranceMapper",
            "otherDetailsMapper",
            "odometerMapper",
            "duplicateKeyMapper",
            "interiorOdometerMapper",
            "interiorElecctricalsMapper",
            "rejectionRemarkMapper",
            "interiorDataMapper",
            "externalDataMapper",
            "airConditioningDataMapper",
            "rcImagesMapper",
            "insuranceImageMapper",
            "exteriorTyresMapper",
            "interiorImagesMapper",
            "lhsFrontTyremapper",
            "rhsFrontTyremapper",
            "lhsRearTyremapper",
            "rhsRearTyremapper",
            "spareTypremapper",
            "additionalImagesMapper"
        ];

        remarkCommentCheckInMappers.forEach(mapper => {

            if(Array.isArray(inspectionDetails[mapper])) {
                inspectionDetails[mapper].map(item => {
                    let isVisible = typeof item.isVisible !== "undefined" ? item.isVisible : true;
                    if(isVisible && item.checked && !item.isEditable && !item.isCommentAdded) {
                        remarkReqdForFields.push(item.text || item.title);
                    }
                });
            }
        });

        //Exceptional case for other details dependent mandatory fields: city and branch
        let cityObj = inspectionDetails["otherDetailsMapper"].find(o => o.key === "city");
        let branchObj = inspectionDetails["otherDetailsMapper"].find(o => o.key === "storeName");

        if(cityObj.checked && branchObj.checked) {
            remarkReqdForFields = remarkReqdForFields.filter(f => f !== "Branch");
        }

        //Exceptional case for RTO NOC ISSUED dependent mandatory fields
        let rtoNocIssuedObj = inspectionDetails["documentsMapper"].find(o => o.key === "rtoNocIssued");
        let rtoNocAvailableObj = inspectionDetails["documentsMapper"].find(o => o.key === "rtoNocAvailable");
        let rtoNmcAvailableObj = inspectionDetails["documentsMapper"].find(o => o.key === "rtoNmcAvailable");
        let rtoNocIssuedForObj = inspectionDetails["documentsMapper"].find(o => o.key === "rtoNocIssuedFor");
        let rtoNocIssuedDateObj = inspectionDetails["documentsMapper"].find(o => o.key === "rtoNocIssuedDate");

        if(
            rtoNocIssuedObj.checked && rtoNocAvailableObj.checked &&
            rtoNmcAvailableObj.checked && rtoNocIssuedForObj.checked &&
            rtoNocIssuedDateObj.checked
        ) {
            remarkReqdForFields = remarkReqdForFields.filter(f => !["RTO NOC Available", "RTO NMC Available", "RTO NOC Issued For", "RTO NOC Issued Date"].includes(f));
        }

        return {
            flag: flag,
            inValidDate: inValidDate,
            fields: fields,
            remarkReqdForFields: remarkReqdForFields.filter(Boolean)
        };
    };

    useEffect(() => {
        if (Documents > 0 || EXTERIOR > 0 || INTERIOR > 0 || SUMMARY > 0) {
            setShowCritical(true);
        }
        else {
            setShowCritical(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Documents, EXTERIOR, INTERIOR, SUMMARY]);

    const critical = [{
        text: "Critical",
        value: 1,
        checked: false
    },
    {
        text: "Non Critical",
        value: 0,
        checked: true
    }];

    const [popupOpen, setPopupOpen] = useState(false);

    const onPopupOpen = () => {
        if (!isValidated().flag) {
            if (isValidated().inValidDate) {
                alert(`Date is in invalid format for Fields: ${isValidated().fields.join()}`);
            }
            
            if(isValidated().fields.length) {
                alert(`Please Edit the following Fields: ${isValidated().fields.join()}`);
            }
        } else if (isValidated().remarkReqdForFields.length) {
            alert(`Please add rejection comment on the following Fields: \n${isValidated().remarkReqdForFields.join("\n")}`);
        } 
        else {
            setPopupOpen(true);
        }
    };
   
    const onDummyPopupClose = (submitReview) => {
        if (!submitReview || Object.keys(submitReview).length > 0) {
            setIsDummyInspection(false);
            setShowCritical(IsEditableFieldTouched());
        }
        setDummyPopupOpen(false);
    };

    const onPopupClose = (submitReview) => {
        if (submitReview === true) {
            setIsReviewSubmitting(true);
            onReviewsubmit();
        }
        setPopupOpen(false);
    };

    const [criticalMapper, setCriticalMapper] = useState(critical);
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);

    const onCheckboxHandler = (value) => {
        let newMapper = [];
        critical.map(item => {
            if (item.value === value) {
                item.checked = true;
            }
            else {
                item.checked = false;
            }
            newMapper.push(item);
        });

        setCriticalMapper(newMapper);
        resetInspectionDetailByKeyConnect("isCritical", value);
    };
    const IsEditableFieldTouched = () => {
        let isTouched = false;
        documentsMapper.map(item => {
            if (item.checked && editableFielKeys.find(field => field === item.key)) {
                isTouched = true;
            }
        });
        insuranceMapper.map(item => {
            if (item.checked && editableFielKeys.find(field => field === item.key)) {
                isTouched = true;
            }
        });
        makeModelVariantMapper.map(item => {
            if (item.checked && item.newValue && item.newId && editableFielKeys.find(field => field === item.key)) {
                isTouched = true;
            }
        });
        // //comments mapper extra logic
        let isRejectedComment = commentsMapper.find(x => x.isRejected || x.isAdded);
        if(isRejectedComment) {
            return true;
        }
        // let commentsRjrejected = commentsMapper.filter(x => x.isImportant === true);
        // let commentsQCrejected = commentsMapper.filter(x => x.checked === true);
        // if ((!!commentsQCrejected && commentsQCrejected.length > 0)) {
        //     if (!!commentsRjrejected && commentsRjrejected.length > 0) {
        //         if (JSON.stringify(commentsQCrejected) !== JSON.stringify(commentsRjrejected)) {
        //             return true;
        //         }
        //     }
        //     else {
        //         return true;
        //     }
        // }
        // //end comments mapper extra logic

        /// mismatch mapper extra logic
        let mismatchRjrejected = mismatchMapper.filter(x => x.isImportant === true);
        let mismatchQCrejected = mismatchMapper.filter(x => x.checked === true);
        if ((!!mismatchQCrejected && mismatchQCrejected.length > 0)) {
            if (!!mismatchRjrejected && mismatchRjrejected.length > 0) {
                if (JSON.stringify(mismatchQCrejected) !== JSON.stringify(mismatchRjrejected)) {
                    return true;
                }
            }
            else {
                return true;
            }
        }

        //odometerComments mapper extra logic
        let odoCommentsRjrejected = odometerComments.filter(x => x.isImportant === true);
        let odoCommentsQCrejected = odometerComments.filter(x => x.checked === true);
        if ((!!odoCommentsQCrejected && odoCommentsQCrejected.length > 0)) {
            if (!!odoCommentsRjrejected && odoCommentsRjrejected.length > 0) {
                if (JSON.stringify(odoCommentsQCrejected) !== JSON.stringify(odoCommentsRjrejected)) {
                    return true;
                }
            }
            else {
                return true;
            }
        }
        // //end comments mapper extra logic
        // interior odometer is editable field now
        let editData = interiorOdometerMapper.filter(x => x.checked === true);
        if (!!editData && editData.length > 0) {
            return true;
        }

        interiorElecctricalsMapper.map(item => {
            if (item.checked && editableFielKeys.find(field => field === item.key)) {
                isTouched = true;
            }
        });

        return isTouched;
    };
    const IsNonEditableFieldTouched = () => {
        let totalNonEditableFieldsTouched = 0;
        // one special case for bankNocStatus, bankNocExpiryDate
        totalNonEditableFieldsTouched += documentsMapper.filter(x => x.checked === true &&
            (x.key === "bankNocStatus" || x.key === "bankNocExpiryDate")).length;

        totalNonEditableFieldsTouched += insuranceImageMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += otherDetailsMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += rcImagesMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += externalDataMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += exteriorTyresMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += interiorDataMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += interiorImagesMapper.filter(x => x.checked === true).length;
        
        totalNonEditableFieldsTouched += airConditioningDataMapper.filter(x => x.checked === true).length;
        
        //lhsFrontTyremapper,rhsFrontTyremapper,lhsRearTyremapper,rhsRearTyremapper,spareTypremapper
        totalNonEditableFieldsTouched += lhsFrontTyremapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += rhsFrontTyremapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += lhsRearTyremapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += rhsRearTyremapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += spareTypremapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += duplicateKeyMapper.filter(x => x.checked === true).length;

        //exterionTyreComments
        if (!!exteriorComments && exteriorComments.checked) {
            totalNonEditableFieldsTouched += 1;
        }

        totalNonEditableFieldsTouched += additionalImagesMapper.filter(x => x.checked === true).length;

        totalNonEditableFieldsTouched += interiorElecctricalsMapper.filter(item => item.checked === true && item.key !== 'fuelLevel').length;
       
        return totalNonEditableFieldsTouched;
    };

    const isImageMaskedByQc = () => {
       
        let totalMaskedExteriorTyres = exteriorTyresMapper.filter(x => x.checked === true && x.isMaskedByQc === true).length;
        
        let totalMaskedAdditionalImages = additionalImagesMapper.filter(x => x.checked === true && x.isMaskedByQc === true).length;
       
        return totalMaskedExteriorTyres + totalMaskedAdditionalImages;
    };

    const showValidationMessage = (errors) => {
        if (errors && Object.keys(errors).length > 0) {
            const errorCategories = Object.keys(errors);
            if (errorCategories && errorCategories.length > 0) {
                errorCategories.forEach(category => {
                    if (errors[category] && errors[category].isValidate === 1 && errors[category].message) {
                        showToastMessages(errors[category].message, false);
                    }
                    if(errors[category].subParts) {
                        showValidationMessage(errors[category].subParts);
                    }
                });
            }
        }
    }

    const onReviewsubmit = () => {
        let reviewJson = {
            "role": "qc",
            "inspectionStatus": "smReview",
            "reviewStartTime": reviewStartTime,
            "reviewEndTime": new Date(),
            "user": email,
            "client": "web",
            "isCritical": isCritical,
            "otherComments": getOtherCommentsJson(rejectionRemark),
            ...(isDummyInspection && {isDummyInspection: 1})
        };
        try {
            let reviewJsonObj = JSON.parse(JSON.stringify(reviewJson));
            if (!isDummyInspection) {
                // function to check that is any editablefield changed
                const isEditableFieldTouched = IsEditableFieldTouched();
                if (isEditableFieldTouched) {
                    reviewJsonObj.inspectionStatus = "smReviewRejected";
                    reviewJsonObj.isApprovedByQc = 1;
                }
                // end function to check that is any editablefield changed
                // function to check that is any non editablefield changed
                const totalNonEditableFieldAlsoTouched = IsNonEditableFieldTouched();
                const totalMaskedImages = isImageMaskedByQc();

                if (totalNonEditableFieldAlsoTouched) {
                    delete reviewJsonObj.isApprovedByQc;
                    reviewJsonObj.inspectionStatus = "smReviewRejected";
                    if (totalMaskedImages >= totalNonEditableFieldAlsoTouched) {
                        reviewJsonObj.isApprovedByQc = 1;
                    }
                }
                // function to check that is any non editablefield changed
                if (isEditableFieldTouched || totalNonEditableFieldAlsoTouched) {
                    // if (Documents > 0 || EXTERIOR > 0 || INTERIOR > 0 || SUMMARY > 0) {

                    reviewJsonObj.sections = {};

                    //added CarDetail node
                    let carDetailJsonObj = JSON.parse(AddCarDetailNode());
                    reviewJsonObj.sections.carDetails = carDetailJsonObj;
                    //end added CarDetail node

                    //added ExterionTyre node
                    let exteriorTyreJsonObj = JSON.parse(AddExteriorTyreNode());
                    reviewJsonObj.sections.exteriorTyres = exteriorTyreJsonObj;
                    //end added ExterionTyre node

                    //added ElectricalsInterior node
                    let electricalsInteriorJsonObj = JSON.parse(ElectricalsInteriorNode());
                    reviewJsonObj.sections.electricalsInterior = electricalsInteriorJsonObj;
                    //end added ElectricalsInterior node

                    //added AirCondition node
                    let acJsonObj = JSON.parse(AcNode());
                    reviewJsonObj.sections.airConditioning = acJsonObj;
                    //end added AirCondition node

                    //added engineTransmission node
                    let engineTransJsonObj = JSON.parse(engineTransmissionNode());
                    reviewJsonObj.sections.engineTransmission = engineTransJsonObj;
                    //end added engineTransmission node

                    //added steeringSuspensionBrakes node
                    let steeringSuspensionBrakesJsonObj = JSON.parse(steeringSuspensionBrakesNode());
                    reviewJsonObj.sections.steeringSuspensionBrakes = steeringSuspensionBrakesJsonObj;
                    //end added steeringSuspensionBrakes node

                    //added summary node
                    if (!!issueType && issueType.length > 0) {
                        let summaryJsonObj = JSON.parse(SummaryNode());
                        reviewJsonObj.sections.summary = summaryJsonObj;
                    }
                    //end added summary node

                }
                else {
                    reviewJsonObj.inspectionStatus = "done";
                }
            }
            // console.log('reviewJsonObj-->>',reviewJsonObj);
            submitReviewConnect(carId, reviewJsonObj).then(resp => {
                console.log({resp});
                if (resp.status === 206) {
                    window.location.reload();
                } else {
                    showToastMessages("Inspection Completed Successfully");
                    setTimeout(() => {
                        history.push ('/inspection');
                    }, 2000);
                }
            }).catch(error => {
                const { detail } = error;
                showToastMessages("Error in submit review - " + !!detail ? detail : error, false);
                showValidationMessage(detail?.errors);
            }).finally(() => {
                setIsReviewSubmitting(false);
            });
        }
        catch (error) {

            alert("Error in post Object - " + error);
            setIsReviewSubmitting(false);
        }

    };
    const getErrorCount = (objArr, prevCount) => {
        let newCount = prevCount;
        try {
            let newObjectArr = [...objArr];
            if (!!newObjectArr && newObjectArr.length > 0) {
                let editObj = newObjectArr.filter(x => x.checked === true);
                if (!!editObj && editObj.length > 0) {
                    newCount = newCount + editObj.length;
                }
            }
            return newCount;
        }
        catch (e) {
            return newCount;
        }
    };
    const getErrorCountForComments = (objArr, prevCount) => {
        let newCount = prevCount;
        try {
            let nodeAdded = false;
            let editedArray = [...objArr];

            let mismatchRjrejected = editedArray.filter(x => x.isImportant === true);
            let mismatchQCrejected = editedArray.filter(x => x.checked === true);
            if ((!!mismatchQCrejected && mismatchQCrejected.length > 0)) {
                if (!!mismatchRjrejected && mismatchRjrejected.length > 0) {
                    if (JSON.stringify(mismatchQCrejected) !== JSON.stringify(mismatchRjrejected)) {
                        nodeAdded = true;
                    }
                }
                else {
                    nodeAdded = true;
                }
            }
            if (nodeAdded) {
                newCount = newCount + 1;
            }
            return newCount;
        }
        catch (e) {
            return newCount;
        }
    };
    const AddCarDetailNode = () => {
        //insuranceMapper;
        let errorCount = 0;
        let editeddocs = documentsMapper.filter(x => x.checked === true);  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": 0
        };
        errorCount = getErrorCount(documentsMapper, errorCount);

        let editInsuranceDocs = insuranceMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(insuranceMapper, errorCount);

        let insuranceImages = insuranceImageMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(insuranceImageMapper, errorCount);

        let editOdoMeterDocs = odometerMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(odometerMapper, errorCount);

        let editMismatchMapperDocs = mismatchMapper;// mismatchMapper.filter(x => x.checked === true);
        errorCount = getErrorCountForComments(mismatchMapper, errorCount);

        let editOtherDetailsMapperDocs = otherDetailsMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(otherDetailsMapper, errorCount);

        let editmakeModelVariantDocs = makeModelVariantMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(makeModelVariantMapper, errorCount);

        let editrcImageDocs = rcImagesMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(rcImagesMapper, errorCount);

        let editCommentsmapper = commentsMapper;// commentsMapper.filter(x => x.checked === true);
        errorCount = getErrorCountForComments(commentsMapper, errorCount);

        let editDuplicatekeyMapper = duplicateKeyMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(duplicateKeyMapper, errorCount);

        let carDetailDocMapper = JSON.parse(JSON.stringify(obj));
        carDetailDocMapper.errorsCount = errorCount;

        // carDetailDocMapper.imagesRatings = getCarDetailImageRatingfieldObject(editeddocs);
        carDetailDocMapper.rejectedFields = getCarDetailRejectedfieldObject(editeddocs, editInsuranceDocs, insuranceImages, editOdoMeterDocs, editMismatchMapperDocs, editOtherDetailsMapperDocs, editmakeModelVariantDocs, editrcImageDocs, editCommentsmapper, editDuplicatekeyMapper, documentsMapper, insuranceMapper);
        carDetailDocMapper.qcEditableFields = getCarDetailPostQcEditablefieldObject(editeddocs, editInsuranceDocs, insuranceImages, editOdoMeterDocs, editMismatchMapperDocs, editOtherDetailsMapperDocs, editmakeModelVariantDocs, editrcImageDocs, editCommentsmapper, editDuplicatekeyMapper, documentsMapper, insuranceMapper);
        return JSON.stringify(carDetailDocMapper);
    };

    const AddExteriorTyreNode = () => {
        //externalDataMapper, exteriorTyresMapper
        let errorCount = 0;
        let editedExteriorTyredocs = externalDataMapper.filter(x => x.checked === true);  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": !!editedExteriorTyredocs ? editedExteriorTyredocs.length : 0
        };

        errorCount = getErrorCount(externalDataMapper, errorCount);
        //lhsFrontTyremapper,rhsFrontTyremapper,lhsRearTyremapper,rhsRearTyremapper,spareTypremapper
        let frontRearTyreEditedImages = [];
        let editedfrontRearTyre = lhsFrontTyremapper.filter(x => x.checked === true);
        errorCount = getErrorCount(lhsFrontTyremapper, errorCount);

        if (!!editedfrontRearTyre && editedfrontRearTyre.length > 0) {
            frontRearTyreEditedImages.push(editedfrontRearTyre[0]);
        }
        editedfrontRearTyre = rhsFrontTyremapper.filter(x => x.checked === true);
        errorCount = getErrorCount(rhsFrontTyremapper, errorCount);
        if (!!editedfrontRearTyre && editedfrontRearTyre.length > 0) {
            frontRearTyreEditedImages.push(editedfrontRearTyre[0]);
        }
        editedfrontRearTyre = lhsRearTyremapper.filter(x => x.checked === true);
        errorCount = getErrorCount(lhsRearTyremapper, errorCount);
        if (!!editedfrontRearTyre && editedfrontRearTyre.length > 0) {
            frontRearTyreEditedImages.push(editedfrontRearTyre[0]);
        }
        editedfrontRearTyre = rhsRearTyremapper.filter(x => x.checked === true);
        errorCount = getErrorCount(rhsRearTyremapper, errorCount);
        if (!!editedfrontRearTyre && editedfrontRearTyre.length > 0) {
            frontRearTyreEditedImages.push(editedfrontRearTyre[0]);
        }
        editedfrontRearTyre = spareTypremapper.filter(x => x.checked === true);
        errorCount = getErrorCount(spareTypremapper, errorCount);
        if (!!editedfrontRearTyre && editedfrontRearTyre.length > 0) {
            frontRearTyreEditedImages.push(editedfrontRearTyre[0]);
        }
        //End lhsFrontTyremapper,rhsFrontTyremapper,lhsRearTyremapper,rhsRearTyremapper,spareTypremapper

        let editedExteriorTyreImagedocs = exteriorTyresMapper.filter(x => x.checked === true);  //for testing making it false
        errorCount = getErrorCount(exteriorTyresMapper, errorCount);
        let exteriorTyreDocMapper = JSON.parse(JSON.stringify(obj));
        exteriorTyreDocMapper.errorsCount = errorCount;
        // exteriorTyreDocMapper.mainImages = getExteriorTyreMainfieldObject(editedExteriorTyreImagedocs);
        exteriorTyreDocMapper.rejectedFields = getExteriorTyresRejectedfieldObject(editedExteriorTyredocs, editedExteriorTyreImagedocs, frontRearTyreEditedImages, exteriorComments, additionalImagesMapper, "exteriorTyres", null, null, null);
        // exteriorTyreDocMapper.qcEditableFields = getCarDetailPostQcEditablefieldObject(editedExteriorTyredocs);
        return JSON.stringify(exteriorTyreDocMapper);
    };
    const ElectricalsInteriorNode = () => {
        //interiorImagesMapper,interiorDataMapper 
        let errorCount = 0;
        let editedExteriorTyredocs = interiorDataMapper.filter(x => x.checked === true);  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": !!editedExteriorTyredocs ? editedExteriorTyredocs.length : 0
        };
        errorCount = getErrorCount(interiorDataMapper, errorCount);

        let editedExteriorTyreImagedocs = interiorImagesMapper.filter(x => x.checked === true);  //for testing making it false
        errorCount = getErrorCount(interiorImagesMapper, errorCount);

        let editInteriorElecctricalsMapper = interiorElecctricalsMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(interiorElecctricalsMapper, errorCount);

        errorCount = getErrorCountForComments(odometerComments, errorCount);

        let exteriorTyreDocMapper = JSON.parse(JSON.stringify(obj));

        // exteriorTyreDocMapper.mainImages = getExteriorTyreMainfieldObject(editedExteriorTyreImagedocs);

        let interiorOdoDocs = interiorOdometerMapper.filter(x => x.checked === true);
        errorCount = getErrorCount(interiorOdometerMapper, errorCount);

        exteriorTyreDocMapper.errorsCount = errorCount;

        exteriorTyreDocMapper.rejectedFields = getExteriorTyresRejectedfieldObject(editedExteriorTyredocs, editedExteriorTyreImagedocs, null, null, additionalImagesMapper, "electricalsInterior", interiorOdoDocs, odometerComments, editInteriorElecctricalsMapper);
        exteriorTyreDocMapper.qcEditableFields = getInteriorExteriorPostQcEditablefieldObject(interiorOdoDocs, odometerComments, editInteriorElecctricalsMapper);
        return JSON.stringify(exteriorTyreDocMapper);

    };
    const AcNode = () => {
        //airConditioningDataMapper
        let editedAcdocs = airConditioningDataMapper.filter(x => x.checked === true);  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": !!editedAcdocs ? editedAcdocs.length : 0
        };
        let acDocMapper = JSON.parse(JSON.stringify(obj));
        acDocMapper.rejectedFields = getACRejectedfieldObject(editedAcdocs);
        return JSON.stringify(acDocMapper);
    };

    const engineTransmissionNode = () => {
        let editedAcdocs = additionalImagesMapper.filter(x => x.checked === true && x.section === "engineTransmission");  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": !!editedAcdocs ? editedAcdocs.length : 0
        };
        let docMapper = JSON.parse(JSON.stringify(obj));
        docMapper.rejectedFields = getEngineTransmissoinRejectedfieldObject(editedAcdocs);
        return JSON.stringify(docMapper);
    };
    const steeringSuspensionBrakesNode = () => {
        let editedAcdocs = additionalImagesMapper.filter(x => x.checked === true && x.section === "steeringSuspensionBrakes");  //for testing making it false
        let obj = {
            "isApproved": 0,
            "errorsCount": !!editedAcdocs ? editedAcdocs.length : 0
        };
        let docMapper = JSON.parse(JSON.stringify(obj));
        docMapper.rejectedFields = getSteeringSuspensionRejectedfieldObject(editedAcdocs);
        return JSON.stringify(docMapper);
    };
    //if(!!issueType && issueType.length > 0)
    const SummaryNode = () => {
        if (!!issueType && issueType.length > 0) {
            let obj = {};
            let acDocMapper = JSON.parse(JSON.stringify(obj));
            acDocMapper.issueType = issueType.map(x => x.id);//getSummaryRejectedfieldObject(issueType);
            return JSON.stringify(acDocMapper);
        }
    };
    
    const onDummyCheckboxHandler = (e) => {
        setIsDummyInspection(!isDummyInspection);
        setDummyPopupOpen(e.target.checked);
        if (e.target.checked) {
            setShowCritical(false);
        } else {
            setShowCritical(IsEditableFieldTouched());
        }
    };
    
    return (
        <div className={styles.inspectionReviewFooter}>
            <div className={styles.comments}>
                <h3>Other Comments</h3>
                <textarea onChange={onTextAreaChange}></textarea>
            </div>
            {!version && <div className={styles.comments}>
                <h3>Is this a Dummy Inspection?</h3>
                <div>
                    <input
                        onChange={onDummyCheckboxHandler}
                        type="checkbox"
                        id={'dummy'}
                        name={'dummy'}
                        checked={!!isDummyInspection}
                    />
                    <label className={styles.lableText}>Dummy Inspection</label>
                </div>
            </div>}
            <div className={styles.footer}>
                <div className={styles.contactSection}>
                    {/* <p className={styles.contact}>CJ Contact:</p>
                    <p>{inspectionBy}</p> */}
                </div>
                <div className={styles.criticalSection}>
                    {showCritical && isAssignedToMe(currentUser) && <ul>
                        {
                            criticalMapper.map((item) => {
                                return <li>
                                    <Checkbox
                                        checkedStatus={item.checked}
                                        onChangeCallback={() => onCheckboxHandler(item.value)}
                                        name={`critical_${item.value}`}
                                        id={`critical_${item.value}`}
                                    />
                                    <span>{item.text}</span>
                                </li>;
                            })
                        }
                    </ul>}
                </div>

                {
                    isAssignedToMe(currentUser) && !(selectedVersion > -1) && <div className={styles.submitSection}>
                        <Button onClick={onPopupOpen} ctaText={"SUBMIT"} classNames="primaryButton" />
                        {/* <Button ctaText={"SUBMIT Review"} classNames="primaryButton" onClick={onReviewsubmit} /> */}
                        <p className={styles.submitText}>To approve this car, you need you discard the changes made
                            earlier.</p>
                    </div>
                }
                {dummyPopupOpen && <Popup isOpen={dummyPopupOpen} close={onDummyPopupClose}>
                    <DummyInspectionConfirmationPopup onClose={onDummyPopupClose} isDummyInspection={true}/>  
                </Popup>}
                {
                    showCritical && <Popup isOpen={popupOpen} close={onPopupClose}>
                        <RejectionPopup onClose={onPopupClose} rejectionDataList={rejectionDataList} />
                    </Popup>
                }

                {
                    <Popup isOpen={popupOpen} close={onPopupClose}>
                        <InspectionConfirmationPopup onClose={onPopupClose} />
                    </Popup>
                }

            </div>
        </div>
    );
};

export default InspectionReviewFooter;