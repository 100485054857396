import React, { useCallback, useEffect, useState } from "react";
import styles from "./inspection-review-car-info.module.css";
import Button from "../shared/button/component";
import InspectionReviewInfoTabs from "../../components/inspection-review-info-tabs/";
import SelectComponent from "../shared/select/component";
import { InspectionService } from "../../service";
import { FUEL_TYPES, monthNames, RESET_REDUCER_KEYS, YES_NO, EMISSION_STANDARD } from "../../utils/app-constants";
import { isAssignedToMe, getUnVerifiedKeys } from "../../utils/helper";
import Tooltip from "../../components/tooltip";

const InspectionReviewCarInfo = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect,
    inputEl,
    docRef,
    intRef,
    sumRef
}) => {
    const { makeModelVariantMapper, qcAssign, documentsMapper, selectedVersion, vahanData } = inspectionDetails;
    const { maker: vahanMake, model: vahanModel, fuelType: vahanFuelType, manufacturingMonthYr: vahanManufacturingDate } = vahanData?.vahanWebsite || {};
    const unVerifiedItemKeys = getUnVerifiedKeys(makeModelVariantMapper);
    const currentUser = qcAssign ? qcAssign.to : "";
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [variant, setVariant] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [scrapType, setScrapType] = useState("");
    const [emissionStandards, setEmissionStandards] = useState({});
    const [show, setShow] = useState(false);
    const [showUpdatedValue, setShowUpdatedValue] = useState(false);

    const [carData, setCarData] = useState([]);

    const [makeDropDown, setMakeDropDown] = useState([]);
    const [modelDropDown, setModelDropDown] = useState([]);
    const [yearDropDown, setYearDropDown] = useState([]);
    const [variantDropDown, setVariantDropDown] = useState([]);

    const [variantDetail, setVariantDetail] = useState(null);

    const [selectedMakeLabel, setSelectedMakeLabel] = useState("");
    const [selectedModelLabel, setSelectedModelLabel] = useState("");
    const [selectedYearLabel, setSelectedYearLabel] = useState("");
    const [selectedMonthLabel, setSelectedMonthLabel] = useState("");
    const [selectedVariantLabel, setSelectedVariantLabel] = useState("");
    const [selectedFuelTypeLabel, setSelectedFuelTypeLabel] = useState("");
    const [selectedScrapTypeLabel, setSelectedScrapTypeLabel] = useState("");

    const [selectedMake, setSelectedMake] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedVariant, setSelectedVariant] = useState("");
    const [selectedFuelType, setSelectedFuelType] = useState("");
    const [selectedScrapType, setSelectedScrapType] = useState("");

    const [yearVariant, setYearVariant] = useState({});
    const [buttonActive, setButtonActive] = useState(false);
    const [makeModelChange, setMakeModelChange] = useState(false);

    // makeModelVariantMapper.find((key) => { key === "make"; });

    // let makeDefaultObj = {};
    // let modelDefaultObj = {};
    // let yearlDefaultObj = {};
    // let variantDefaultObj = {};
    // let fuelTypeDefaultObj = {};
    // let tobeScrapDefaultdObj = {};

    const [makeDefaultObj, setmakeDefaultObj] = useState({});
    const [modelDefaultObj, setmodelDefaultObj] = useState({});
    const [yearlDefaultObj, setyearlDefaultObj] = useState({});
    const [monthlDefaultObj, setmonthlDefaultObj] = useState({});
    const [variantDefaultObj, setvariantDefaultObj] = useState({});
    const [fuelTypeDefaultObj, setfuelTypeDefaultObj] = useState({});
    const [tobeScrapDefaultdObj, settobeScrapDefaultdObj] = useState({});

    const monthDropDown = monthNames.map((month, index) => {
        return {
            label: month,
            value: index + 1
        };
    });

    useEffect(() => {
        if (!makeModelChange) {
            //toBeScrapped
            const tobeScrap = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "toBeScrapped";
                return keyArr;
            });

            if (!!tobeScrap) {
                settobeScrapDefaultdObj({ label: tobeScrap.value, value: tobeScrap.key });
                // setSelectedScrapType(tobeScrap.key);
                // setSelectedScrapTypeLabel(tobeScrap.value);
            }

            const makeDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "make";
                return keyArr;
            });

            if (!!makeDefaultObj) {
                setmakeDefaultObj({ label: makeDefault ? makeDefault.value : "", value: makeDefault ? makeDefault.id : "" });
            }

            const modelDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "model";
                return keyArr;
            });

            if (!!modelDefault) {
                setmodelDefaultObj({ label: modelDefault.value, value: modelDefault.id });
            }
            const yearlDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "year";
                return keyArr;
            });

            if (!!yearlDefault) {
                setyearlDefaultObj({ label: yearlDefault.value, value: yearlDefault.id });
            }
            const monthlDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "month";
                return keyArr;
            });

            if (!!monthlDefault) {
                setmonthlDefaultObj({ label: monthlDefault.value, value: monthlDefault.id });
            }

            const variantDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "variant";
                return keyArr;
            });

            if (!!variantDefault) {
                setvariantDefaultObj({ label: `${variantDefault.value} (${variantDefault.variant_display_name})`, value: variantDefault.id });
            }
            const fuelTypeDefault = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "fuelType";
                return keyArr;
            });

            if (!!fuelTypeDefault) {
                setfuelTypeDefaultObj({ label: fuelTypeDefault.value, value: fuelTypeDefault.id });
            }

            const emmisionStandardObj = makeModelVariantMapper.find((v) => {
                let keyArr = v.key === "emissionStandards";
                return keyArr;
            });

            if (!!emmisionStandardObj) {
                setEmissionStandards(emmisionStandardObj);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedScrapType(tobeScrapDefaultdObj.key);
        setSelectedScrapTypeLabel(tobeScrapDefaultdObj.label);
        InspectionService.getMake()
            .then(res => {
                const { data: { detail } } = res;
                if (detail.length > 0) {
                    setMakeDropDown(detail.map(make => {
                        return {
                            label: make.make_name,
                            value: make.make_id
                        };
                    }));
                }
            })
            .catch(err => {

            });

        return () => {
            setSelectedMake("");
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (show === true) {
            // setSelectedMake("");
        }
    }, [show]);

    useEffect(() => {
        if (selectedMake) {
            InspectionService.getModel(selectedMake)
                .then(response => {
                    const { data: { detail } } = response;
                    setModelDropDown(detail.map(model => {
                        return {
                            label: model.model_name,
                            value: model.model_id
                        };
                    }));
                }).catch(error => {

                    // setModel(getModel(selectedMake).detail);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMake]);

    useEffect(() => {
        if (selectedModel) {
            InspectionService.getVariantYear(selectedModel).then(response => {
                let { data: { detail } } = response;
                // if (!!detail && detail.length > 0) {
                //     detail = detail.filter(x => x.variant_status === "Active" && x.variantyear_status === "Active");
                // }
                setYearVariant(detail);
                const YearDropDown = Object.keys(detail).map((item) => {
                    return {
                        label: item,
                        value: item
                    };
                });
                setYearDropDown(YearDropDown);
            }).catch(error => {

            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModel]);

    // useEffect(() => {
    //     if (selectedYear) {
    //         if (yearVariant && yearVariant[selectedYear]) {
    //             const VariantDropDown = yearVariant[selectedYear].map((item) => {
    //                 return {
    //                     label: item.variant_name,
    //                     value: item.variant_id
    //                 };
    //             });
    //             setVariantDropDown(VariantDropDown);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedYear]);

    useEffect(() => {
        if (selectedFuelType) {
            // setVariantDropDown([]);
            InspectionService.getVariantYearByFuelType(selectedModel, selectedFuelType)
                .then((response) => {
                    setVariantDropDown([]);
                    let { data: { detail } } = response;
                    // if (!!detail && detail.length > 0) {
                    //     detail = detail.filter(x => x.variant_status === "Active" && x.variantyear_status === "Active");
                    // }
                    let currentVariant = detail && detail[selectedYear] || [];
                    if (!!currentVariant && currentVariant.length > 0) {
                        currentVariant = currentVariant.filter(x => x.variant_status === "Active" && x.variantyear_status === "Active");
                    }
                    const VariantDropDown = currentVariant.map((item) => {
                        return {
                            label: `${item.variant_name} (${item.variant_display_name})`,
                            value: item.variant_id,
                            variantName: item.variant_name,
                            variantParentId: item.variant_parent_id,
                        };
                    });
                    setVariantDetail(currentVariant);
                    setVariantDropDown(VariantDropDown);
                })
                .catch(e => {

                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFuelType]);

    useEffect(() => {
        if (makeModelVariantMapper.length > 0) {
            //check CNG Fitment
            const fuelType = makeModelVariantMapper.find(item => item.key === "fuelType");
            const initialValue = fuelType.value;
            const newValue = fuelType.newValue;
            const index = documentsMapper.findIndex(item => item.key.match(/cngLpg/i));
            if (!newValue && (initialValue.match(/diesel/i) || initialValue.match(/electric/))) {
                documentsMapper[index].isVisible = false;
                documentsMapper[index].checked = false;
                documentsMapper[index].isDisabled = false;
                documentsMapper[index].text = "";
            }
            //if anything is changed
            else if (newValue && (newValue.match(/diesel/i) || newValue.match(/electric/i))) {
                documentsMapper[index].isVisible = false;
                documentsMapper[index].checked = false;
                documentsMapper[index].isDisabled = false;
                documentsMapper[index].text = "";
            }
            else if (newValue && (!newValue.match(/diesel/i) || !newValue.match(/electric/i))) {
                documentsMapper[index].isVisible = true;
                documentsMapper[index].checked = true;
                documentsMapper[index].isDisabled = true;
                documentsMapper[index].text = "CNG/LPG Fitment in RC";
            }

            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, documentsMapper);
            setCarData(() => {
                return Array.from(Array(8), (_i, index) => {
                        return {
                            key: makeModelVariantMapper[index]?.key,
                            value: makeModelVariantMapper[index]?.value,
                            commentFlag: makeModelVariantMapper[index]?.commentFlag,
                            isVerified: makeModelVariantMapper[index]?.isVerified
                        }
                })
            })
            setMake(makeModelVariantMapper[0].newValue ? makeModelVariantMapper[0].newValue : makeModelVariantMapper[0].value);
            setSelectedMake(makeModelVariantMapper[0].newId ? makeModelVariantMapper[0].newId : makeModelVariantMapper[0].id);
            setModel(makeModelVariantMapper[1].newValue ? makeModelVariantMapper[1].newValue : makeModelVariantMapper[1].value);
            setSelectedModel(makeModelVariantMapper[1].newId ? makeModelVariantMapper[1].newId : makeModelVariantMapper[1].id);
            setYear(makeModelVariantMapper[2].newValue ? makeModelVariantMapper[2].newValue : makeModelVariantMapper[2].value);
            setSelectedYear(makeModelVariantMapper[2].newId ? makeModelVariantMapper[2].newId : makeModelVariantMapper[2].value);
            setMonth(makeModelVariantMapper[3].newValue ? makeModelVariantMapper[3].newValue : makeModelVariantMapper[3].value);
            setSelectedMonth(makeModelVariantMapper[3].newId ? makeModelVariantMapper[3].newId : makeModelVariantMapper[3].value);
            setVariant(makeModelVariantMapper[4].newValue ? makeModelVariantMapper[4].newValue : makeModelVariantMapper[4].value);
            setSelectedVariant(makeModelVariantMapper[4].newId ? makeModelVariantMapper[4].newId : makeModelVariantMapper[4].id);
            setFuelType(makeModelVariantMapper[5].newValue ? makeModelVariantMapper[5].newValue : makeModelVariantMapper[5].value);
            setSelectedFuelType(makeModelVariantMapper[5].newId ? makeModelVariantMapper[5].newId : makeModelVariantMapper[5].id);
            setScrapType(makeModelVariantMapper[6].newValue ? makeModelVariantMapper[6].newValue : makeModelVariantMapper[6].value);
            setEmissionStandards((prev) => ({
                ...prev,
                value: makeModelVariantMapper[7].newValue ? makeModelVariantMapper[7].newValue : makeModelVariantMapper[7].value,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [makeModelVariantMapper]);

    const showDropDowns = () => {
        const values = [
            {
                key: "make",
                label: selectedMakeLabel,
                id: selectedMake
            },
            {
                key: "model",
                label: selectedModelLabel,
                id: selectedModel
            },
            {
                key: "year",
                label: selectedYearLabel,
                id: selectedYear
            },
            {
                key: "month",
                label: selectedMonthLabel,
                id: selectedMonth
            },
            {
                key: "variant",
                id: selectedVariant,
                label: selectedVariantLabel
            },
            {
                key: "fuelType",
                label: selectedFuelTypeLabel,
                id: selectedFuelType
            },
            {
                key: "toBeScrapped",
                label: selectedScrapTypeLabel,
                id: selectedScrapType
            },
            {
                key: "emissionStandards",
                label: emissionStandards.label,
                id: emissionStandards.id
            }

        ];
        // let foundEmptyValue = values.find((v) => {
        //     let keyArr = v.id === "";
        //     return keyArr;
        // });
        let foundEmptyValue = values.filter(x => x.id === "");
        if (!!foundEmptyValue && foundEmptyValue.length > 0) {
            alert("Select all the fields");
        }
        else {
            setMakeModelChange(false);
            setNewValues(values);
        }
    };

    const setNewValues = (values) => {
        let mapper = [...makeModelVariantMapper];
        values.map(field => {
            mapper = mapper.map(map => {
                let element = { ...map };
                if (map.key === field.key) {
                    element.newValue = field.label;
                    element.newId = field.id;
                    element.checked = true;
                    if (map.key === "variant") {
                        if (!!variantDetail) {
                            let variantArr = variantDetail.filter(x => x.variant_id === field.id);
                            if (!!variantArr && variantArr.length > 0) {
                                element.transportation_category = variantArr[0].transportation_category;
                                element.variant_display_name = variantArr[0].variant_display_name;
                                element.variantParentId = variantArr[0].variant_parent_id;
                                element.transmissionType = variantArr[0].transmission_type;
                            }
                        }
                    }
                }

                return element;
            });

            resetInspectionDetailByKeyConnect("makeModelVariantMapper", mapper);
            setShowUpdatedValue(true);
            setShow(!show);
            setButtonActive(false);
        });

    };

    const onMakeChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedMake(option);
        setSelectedMakeLabel(label);
        setSelectedModel("");
        setSelectedYear("");
        setSelectedVariant("");
        setSelectedYearLabel("");
        setSelectedFuelType("");
        setSelectedFuelTypeLabel("");
        setSelectedVariantLabel("");
        setSelectedModelLabel("");
        setmodelDefaultObj({});
        setyearlDefaultObj({});
        setmonthlDefaultObj({});
        setSelectedMonth("");
        setvariantDefaultObj({});
        setfuelTypeDefaultObj({});
        setButtonActive(true);
    };
    const onModelChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedModel(option);
        setSelectedModelLabel(label);
        setSelectedYear("");
        setSelectedVariant("");
        setSelectedYearLabel("");
        setSelectedVariantLabel("");
        setSelectedFuelType("");
        setSelectedFuelTypeLabel("");
        setmonthlDefaultObj({});
        setSelectedMonth("");
        setyearlDefaultObj({});
        setvariantDefaultObj({});
        setfuelTypeDefaultObj({});
        setButtonActive(true);
    };
    const onYearChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedYear(option);
        setSelectedYearLabel(label);
        setSelectedVariant("");
        setSelectedVariantLabel("");
        setSelectedFuelType("");
        setSelectedFuelTypeLabel("");
        setvariantDefaultObj({});
        setfuelTypeDefaultObj({});
        setButtonActive(true);
        setSelectedFuelType("");
        setSelectedFuelTypeLabel("");
        setmonthlDefaultObj({});
        setSelectedMonth("");
    };

    const onMonthChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedMonth(option);
        setSelectedMonthLabel(label);
        // setSelectedVariant("");
        // setSelectedVariantLabel("");
        // setSelectedFuelType("");
        // setSelectedFuelTypeLabel("");
        // setvariantDefaultObj({});
        // setfuelTypeDefaultObj({});
        setButtonActive(true);
        // setSelectedFuelType("");
        // setSelectedFuelTypeLabel("");
    };

    const onVariantChange = (option, label, optionData) => {
        setMakeModelChange(true);
        setSelectedVariant(option);
        setSelectedVariantLabel(optionData?.variantName || "");
        setfuelTypeDefaultObj({});
        setButtonActive(true);
    };
    const onFuelChange = (option, label) => {
        setSelectedFuelType(option);
        setvariantDefaultObj({});
        setSelectedFuelTypeLabel(label);
        setSelectedVariant("");
        setSelectedVariantLabel("");
        setButtonActive(true);
    };
    const onScrapChange = (option, label) => {
        setSelectedScrapType(option);
        setSelectedScrapTypeLabel(label);
        setButtonActive(true);
    };

    const onEmissionStandardChange = (option, label) => {
        setEmissionStandards({
            ...emissionStandards,
            id: option,
            label: label
        });
        setButtonActive(true);
    }

    const getTooltipContent = (field) => {
        switch(field) {
            case "make":
                return `Make: ${vahanMake}`;
            case "model":
                return `Model: ${vahanModel}`;
            case "year":
                return `Year: ${vahanManufacturingDate?.split('/')[1] || ''}`;
            case "month":
                return `Month: ${monthNames[parseInt(vahanManufacturingDate?.split('/')[0]) - 1] || ''}`;
            case "variant":
                return `Variant: `;
            case "fuelType":
                return `Fuel Type: ${vahanFuelType}`;
            case "scrapType":
                return `Scrap Type: `;
            case "emissionStandards":
                return `Emission Standard: `;
            default:
                return "";   
        }
    };

    const getVaahanFlag = (verified) => {
        if(verified) {
            return "yes"
        } else if(verified === 0) {
            return "info"
        }
    };

    // do we need to show details coming from vahaan?
    const renderMakeModelVariantInfo = useCallback(() => {
        return carData?.map((_d) => {
            return _d?.value && (
                <p style={{display: "flex", alignItems: "center"}}>
                    {_d.key !== "toBeScrapped" && <Tooltip vaahanFlag={getVaahanFlag(_d?.isVerified)} vahanWebsite={getTooltipContent(_d?.key)} commentFlag={_d?.commentFlag} isVerified={_d?.isVerified}/>}
                    {_d.key === "toBeScrapped" ? 
                        <span className={styles.scrap}>{`Scrap(${_d?.value})`}</span> :
                        <span>{_d?.value}</span>
                    }
                </p>
            )
        })
    },[carData])

    return (
        <div className={styles.inspectionReviewCarInfo}>
            <div className={styles.inspectionText}>
                {
                    <div className={styles.infoChange}>
                        <div>
                            <div className={styles.inspectionInfoWrapper}>
                                {carData.length > 0 && renderMakeModelVariantInfo()}
                            </div>
                            {
                                showUpdatedValue &&
                                <div>
                                    <div className={styles.inspectionInfoWrapper}>
                                        <p className={styles.change}>
                                            Changed To <span>{`${make} ${model} ${year} ${month} ${variant} ${makeModelVariantMapper[4].variant_display_name ? `(${makeModelVariantMapper[4].variant_display_name})`: ""} ${fuelType} Scrap(${scrapType}) ${emissionStandards?.value}`}</span></p>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            isAssignedToMe(currentUser) && <Button classNames="grayButton" ctaText={"Change"} onClick={() => {
                                setShow(!show);
                                setButtonActive(false);
                            }} />
                        }
                    </div>

                }
                <InspectionReviewInfoTabs inputEl={inputEl} docRef={docRef} intRef={intRef} sumRef={sumRef} />
            </div>

            {
                show && <div className={styles.makeModelVariantYear}>
                    <div className={styles.filters}>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={makeDropDown}
                                placeholder="Make"
                                // defaultValue={{ label: "BMW", value: 5 }}
                                defaultValue={makeDefaultObj}
                                onChange={onMakeChange}
                                isDisabled={!unVerifiedItemKeys.includes('make')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={modelDropDown}
                                placeholder="Model"
                                name={selectedMake}
                                defaultValue={modelDefaultObj}
                                onChange={onModelChange}
                                isDisabled={!unVerifiedItemKeys.includes('model')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={yearDropDown}
                                placeholder="Year"
                                onChange={onYearChange}
                                defaultValue={yearlDefaultObj}
                                name={selectedModel}
                                isDisabled={!unVerifiedItemKeys.includes('year')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={monthDropDown}
                                placeholder="Month"
                                onChange={onMonthChange}
                                defaultValue={monthlDefaultObj}
                                name={selectedYear}
                                isDisabled={!unVerifiedItemKeys.includes('month')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={FUEL_TYPES}
                                placeholder="Fuel Type"
                                onChange={onFuelChange}
                                defaultValue={fuelTypeDefaultObj}
                                name={selectedMonth}
                                isDisabled={!unVerifiedItemKeys.includes('fuelType')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                placeholder="Variant"
                                optionsList={variantDropDown}
                                onChange={onVariantChange}
                                name={selectedFuelType}
                                defaultValue={variantDefaultObj}
                                isSearchable={true}
                                isDisabled={!unVerifiedItemKeys.includes('variant')}
                            /></div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={YES_NO}
                                defaultValue={tobeScrapDefaultdObj}
                                placeholder="To Be Scrapped"
                                onChange={onScrapChange}
                                isDisabled={!unVerifiedItemKeys.includes('toBeScrapped')}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={EMISSION_STANDARD}
                                defaultValue={EMISSION_STANDARD.find(x => x.value === emissionStandards.value)}
                                placeholder="Emmission Standard"
                                onChange={onEmissionStandardChange}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            isAssignedToMe(currentUser) && !(selectedVersion > -1) && <Button classNames={!buttonActive ? "secondaryButton" : "primaryButton"}
                                ctaText={"save changes"} disabled={!buttonActive} onClick={showDropDowns} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default InspectionReviewCarInfo;
